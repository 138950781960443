import { render, staticRenderFns } from "./EventCard.vue?vue&type=template&id=3e4c7710&scoped=true"
import script from "./EventCard.vue?vue&type=script&lang=js"
export * from "./EventCard.vue?vue&type=script&lang=js"
import style0 from "./EventCard.vue?vue&type=style&index=0&id=3e4c7710&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e4c7710",
  null
  
)

export default component.exports